import React, { PureComponent, useState, useCallback, useEffect } from "react";
import CountDownBlock from "./CountDownBlock";

import Link from "../components/LinkWithTransitionLock"
import Helmet from "react-helmet"
import InViewObserver from "../components/InViewObserver"
import PageContentHeadSwitch from "../components/PageCententHeadSwitch"

import { Expo, gsap } from 'gsap';

class Page extends PureComponent {
  constructor(props) {
     super(props);
  }
  
  componentDidMount() {
    
    InViewObserver();
    
  }

  componentWillUnmount() {}

  render() {

    var next_visual = require('../images/home/project_c.png');

    return (
      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">策展人/方彥翔
            <PageContentHeadSwitch/>
          </h2>
          <h1 className="main_title">單人部落</h1>
          {/* <h6 className="speaker">策展人/方彥翔</h6> */}
        </div>

        <div className="page_content project_b">

          <section className="row_area_s project_b_a">
            <div className="column_5">
              <div className="context">
                <h6>本計畫是對當代居住性一種描繪的嘗試，試圖從對當代生活中「一人成為聚落如何可能」出發，啟動對關係與連結的想像。</h6>
                <p>在「單人部落」已成為都會生活中的主流單位，一種個體自我滿足更輕易達成，與各種體制、工具保持一種密切依存又若即若離的孤立生活。思考社宅公共藝術之公共性時，如何面對更為深切的個體性與個人狀態；在分子化社會動態中，如何形成聚落的內裏。裡面交雜從私人到共同生活的複雜途徑中，生成、排拒、翻轉的細膩過程。甚至，透過這樣方法的提示，讓社宅生活中那孤立與被隔絕的處境得以被關注。</p>
                <p>計畫將從針對「單人生活」的田野調查開始。與年老弱勢者、獨身青創者、和更多習於孤獨隱藏於城市生活的人交談。看見其「單人部落」如何組成，如何散佚。</p>
                
                <Link to="/project_b_article" className="article_link">
                  <h5 className="btn_inner">一個論述的提案（上）</h5>
                </Link>
                <br/>
                <Link to="/project_b_article_2" className="article_link">
                  <h5 className="btn_inner">一個論述的提案（下）</h5>
                </Link>
              </div>
            </div>
          </section>

          <section className="row_area_s project_b_b row_break">
            <div className="column_6">
              <div className="context">
                <p><strong>策展單位禾磊藝術，也對此進行特別策劃。邀請藝文界人士，針對「孤獨」撰寫一小段文字，集結成孤獨手冊，於網路上公布這本孤獨手冊的詩句內容。隨著螢幕背景影像的光火逐漸燃盡，手冊文字也將消逝於網路，成為一瞬的藝術片刻。讓我們想起曾經的孤獨。由眾人找到孤獨，再由孤獨看見眾人。</strong></p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_b_e enter_cover">
            <div className="column_8 enter_cover_container">
              <div className="context">
                <h6>Hi, 歡迎來到「孤獨手冊」</h6>
                <p>本場演出由20位創作者共同創作，當您進入演出後，將可以與孤獨詩文互動。</p>
                <p>但請注意，<strong>每台裝置「每日只有一次」觀賞演出的機會</strong>，一旦關上視窗後，該裝置當天就無法再度進入本演出。本演出將展出至 5/13，請務必選好最佳觀賞角度、裝置以及一段留時間給自己，參與每日一次的演出。</p>
                <p>當你準備好後，就可以進入參與這場盛會了。</p>
                <Link to="/campfires/" className="enter_cover_btn">
                  <div className="btn_inner">請進場</div>
                </Link>
              </div>
            </div>
          </section>

        </div>

        <div className="head_intro_content headroom">
          <h5 className="name">方彥翔</h5>
          <h6 className="title">高雄市立美術館 助理策展人</h6>
          <p className="bio">國⽴台灣藝術⼤學造形藝術研究所畢業。從事當代藝術策展、評論、書寫與創作等⼯作。從獨立藝術空間營運策畫之經驗開始，方彥翔關注著當代藝術與社群運動發展的對話關係，視策展與藝術書寫為一種的聚合行動。目前研究重心放在全球化境況下文化生產之歷史條件與動能，以及如何面對跨境生命治理而提出另類的可能方案。</p>
        </div>

        <div className="page_footer">
          <Link to="/project_c" className="next_page">
            <div className="next_title">駐村編輯</div>
            <div className="next_owner">策展人/林書豪</div>
            <div className="next_visual" style={ { backgroundImage: "url(" + next_visual + ")" } }></div>
          </Link>
        </div>

      </div>

      // <CountDownBlock blockType="countDown" targetDate={new Date(2020, 3, 27, 22)}>
      //   <h4>《單人部落》</h4>
      //   <h5>一人成為聚落如何可能</h5>
      //   <h6>方彥翔/高雄市立美術館 助理策展人</h6>
      // </CountDownBlock>
    );
  }
}


export default Page
